<script>
  import { Carousel, Slide } from "vue3-carousel";
  import { UserCheckIcon } from "vue-feather-icons";
  export default {
    components: {
      Carousel,
      Slide,
      UserCheckIcon,
    },
    props: {
      props: Object
      // props: {
      //   type: Object,
      //   default: () => {}
      // }
    },
  //   data() {
  //     return {
  //       benefits: [
  //         {
  //           title: "Edad",
  //           description: "Edad de ingreso a la póliza, para fines de cobertura, no podrá ser menor de tres (3) meses ni mayor de ocho (08) años. Edad límite de permanencia hasta cumplir los doce (12) años.",
  //         },
  //         {
  //           title: "Consulta de Rutina",
  //           description: "Cubre los gastos usuales y razonables de una consulta médica veterinaria, en las clínicas / hospitales veterinarios o en Prestadores de Servicio afiliados a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
  //         },
  //         {
  //           title: "Responsabilidad Civil",
  //           description: "La Compañía pagará en nombre del asegurado todas las sumas que legalmente este se vea obligado a pagar, en su condición de propietario de la (s) mascota (s) indicada (s) en las condiciones particulares de la póliza, por daños y perjuicios que hayan originado, dentro del período de vigencia de la misma.",
  //         },
  //         {
  //           title: "Emergencias",
  //           description: "Cubre los gastos veterinarios usuales y razonables de emergencia /urgencias hospitalarias, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
  //         },
  //         {
  //           title: "Cirugías",
  //           description: "Cubre los gastos usuales y razonables de la cirugía incluyendo la anestesia y honorarios veterinarios, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
  //         },
  //         {
  //           title: "Hospitalización",
  //           description: "Cubre los gastos usuales y razonables de hospitalización, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
  //         },
  //         {
  //           title: "Examen de laboratorio e imágenes",
  //           description: "Cubre los gastos usuales y razonables de los exámenes, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
  //         },
  //         {
  //           title: "Tratamiento Odontológico",
  //           description: "Cubre los gastos usuales y razonables de tratamiento odontológico, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados a seguir y en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
  //         },
  //         {
  //           title: "Parto",
  //           description: "Cubre los honorarios veterinarios usuales y razonables, en las clínicas / hospitales veterinarios afiliadas a la Red, según los beneficios, límites, términos y Periodo de Carencia indicados a seguir y en las Condiciones Particulares o Certificado de Seguro de esta póliza.",
  //         },
  //       ]
  //     };
  //   }
   };
</script>

<template>
  <div class="benefits-list text-center py-5 marg">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="font-weight-bold">{{props.title}}</h2>
        </div>
        <div class="col-12 mb-4">
          <p class="lead text-muted">{{props.description}}</p>
        </div>
        <div class="col-12 py-4">
          <Carousel v-bind:perPageCustom="[[0, 1], [600, 2], [1024, 3]]" v-bind:autoplay="true">
            <Slide v-for="(item, index) in props.items"
              v-bind:key="index">
              <div>
                <div class="card text-center rounded border-0">
                  <div class="card-body">
                    <div class="p-3 bg-light rounded shadow d-inline-block">
                      <UserCheckIcon size="48" color="var(--aff-primary)" />
                    </div>
                    <div class="mt-4">
                      <h5>
                        <a href="javascript:void(0)" class="text-dark">{{item.title}}</a>
                      </h5>
                      <p class="text-muted mt-3 mb-0">{{item.description}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  figure {
    background: var(--aff-primary);
    border-radius: 50%;
    display: inline-block;
    padding: 8px;
  }
  .marg{
    margin-top: 100px;
  }
</style>